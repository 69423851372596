/*WalletButton.css*/
@font-face {
  font-family: 'KoPubWorldDotum_Pro-Medium';
  src: url('../fonts/KoPubWorld_Dotum_ProMedium.woff') format('woff');
}

.wallet-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-button button {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 25px; 
  cursor: pointer;
  padding: 10px 20px;
  color: #ffffff;
  font-family: "KoPubWorldDotum_Pro-Medium", Helvetica;
  font-size: 15px;
}

.wallet-button .wallet-address {
  margin-top: 10px;
  color: #ffffff;
  font-family: "KoPubWorldDotum_Pro-Medium", Helvetica;
  font-size: 12px;
  text-align: center;
}