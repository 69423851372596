@font-face {
  font-family: 'Jalnan2';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KoPubWorldDotum_Pro-Bold';
  src: url('../fonts/KoPubWorld_Dotum_ProBold.woff') format('woff');
}
@font-face {
  font-family: 'KoPubWorldDotum_Pro-Medium';
  src: url('../fonts/KoPubWorld_Dotum_ProMedium.woff') format('woff');
}
@font-face {
  font-family: 'NanumGothic_Regular';
  src: url('../fonts/NanumGothic-Regular.woff2') format('woff2');
}

.staking {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #317ed6;
  padding: 20px;
  box-sizing: border-box;
}

.staking-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.staking-eng, .staking-ko, .yield {
  margin-bottom: 5px;
  text-align: center;
  color: #ffffff;
}

.staking-eng {
  font-family: "KoPubWorldDotum_Pro-Medium", Helvetica;
  font-size: 15px;
}

.staking-ko {
  font-family: "Jalnan2", Helvetica;
  font-size: 24px;
}

.yield p {
  font-family: "KoPubWorldDotum_Pro-Bold", Helvetica;
  font-size: 13px;
}  

.staking-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.coming-soon p {
  font-family: "Jalnan2", Helvetica;
  font-size: 77px;
  font-weight: 400;
  color: #d9d9d9;
}

.main-link-container {
  margin-top: auto;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}

.main-link {
  color: #d9d9d9;
  font-family: "NanumGothic_Regular", Helvetica;
  font-size: 15px;
  text-decoration: none;
}