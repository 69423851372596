/* DEX.css */
@font-face {
  font-family: 'Jalnan2';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
}
@font-face {
  font-family: 'KoPubWorldDotum_Pro-Bold';
  src: url('../fonts/KoPubWorld_Dotum_ProBold.woff') format('woff');
}
@font-face {
  font-family: 'KoPubWorldDotum_Pro-Medium';
  src: url('../fonts/KoPubWorld_Dotum_ProMedium.woff') format('woff');
}
@font-face {
  font-family: 'NanumGothic_Regular';
  src: url('../fonts/NanumGothic-Regular.woff2') format('woff2');
}

.dex {
  position: relative;
  width: 100%;
  min-height: 100vh; /* 1) 배경 이미지 전체 화면 채우기 */
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center; /* 3) 컴포넌트 중앙 정렬 */
}

.back-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.dexback-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-wrapper {
  position: relative;
  z-index: 1;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* 3) 컴포넌트 중앙 정렬 */
  width: 100%;
  max-width: 390px; /* 2) 모바일 최적화 */
}

.dex-swap {
  margin-top: 20px;
  font-size: 15px;
  font-family: "KoPubWorldDotum_Pro-Medium", sans-serif;
  color: #ffffff;
  text-align: center; /* 3) 텍스트 중앙 정렬 */
}

.dex h2 {
  font-size: 30px;
  margin-bottom: 5px;
  font-family: "Jalnan2", sans-serif;
  color: #ffffff;
  text-align: center; /* 3) 텍스트 중앙 정렬 */
}

.fast-transfer {
  font-size: 15px;
  font-family: "KoPubWorldDotum_Pro-Bold", sans-serif;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center; /* 3) 텍스트 중앙 정렬 */
}

.dex-box {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 25px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 50px;
  width: 90%; 
  max-width: 390px;
  position: relative;
}

.info-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  text-align: left;
}

.price-info {
  border: 2px solid #48b8e9;
  color: #0a2e6c;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 0.5;
}

.balance-info {
  font-size: 13px;
  line-height: 1;
}

.token-select {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.token-icon {
  width: 27px;
  height: 27px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.nas-icon {
  width: 30px !important;
  height: 30px !important;
}

.dex select, .dex input {
  font-size: 15px;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #48b8e9;
  border-radius: 5px;
}

.amount-switch-container {
  font-size: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  width: 250px;
  margin-left: 40px;
}

.amount-input {
  flex: 1;
  margin-right: 10px;
  font-size: 15px;
}

.switch-button {
  font-size: 15px;
  padding: 10px;
  background-color: #195bd6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.estimated-amount {
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center;
}

.gas-slippage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  font-size: 12px;
}

.gas-estimate, .slippage-input {
  display: flex;
  align-items: center;
}

.gas-estimate {
  margin-bottom: 10px;
}

.gas-estimate label, .slippage-input label {
  margin-right: 5px;
}

.slippage-input label {
  margin-bottom: 10px;
}

.slippage-input input {
  width: 50px;
  padding: 5px;
  font-size: 14px;
}

.max-swap-amount {
  margin-bottom: 13px;
  font-size: 13px;
  text-align: center;
}

.swap-button {
  width: 100%;
  padding: 10px 20px;
  background-color: #195bd6;
  color: white;
  font-size: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.fee-info {
  color: #48b8e9;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

.recent-transactions {
  margin-top: 10px;
  margin-right: 30px;
  color: #ffffff;
  width: 100%; 
  max-width: 390px;
}

.transactions-box {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  margin-top: 10px;
  overflow-y: auto;
  min-height: 35px;
}

.transactions-box ul {
  list-style-type: none;
  padding: 0;
}

.transactions-box li {
  margin-bottom: 10px;
  font-size: 12px;
  font-family: "KoPubWorldDotum_Pro-Medium", sans-serif;
}

.tx-hash, .tx-tokens, .tx-amount {
  display: block;
  margin-bottom: 3px;
}

.main-link {
  font-family: "KoPubWorldDotum_Pro-Medium", sans-serif;
  font-size: 14px;
  color: #ffffff !important;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px; 
  left: 0;
}

@media screen and (max-width: 390px) {
  .dex {
    padding: 10px;
  }
  
  .dex-box, .recent-transactions {
    width: 85%;
  }

  .info-container {
    flex-direction: column;
  }

  .price-info, .balance-info {
    margin-bottom: 10px;
  }

  .gas-slippage {
    flex-direction: column;
    align-items: flex-start;
  }

  .slippage-input {
    margin-top: 10px;
  }
}