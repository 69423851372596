/*Game.css*/

@font-face {
    font-family: 'Jalnan2';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
  }
  
@font-face {
    font-family: 'KoPubWorldDotum_Pro-Medium';
    src: url('../fonts/KoPubWorld_Dotum_ProMedium.woff') format('woff');
  }

  .game-page {
    background-color: #195bd6;
    min-height: 100vh;
    width: 100%;
    max-width: 390px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .game-title {
    text-align: center;
    margin-top: 30px;
  }
  
  .game-title .title-text {
    font-family: "Jalnan2", Helvetica;
    font-size: 70px;
    color: #d9d9d9;
    margin: 0;
  }
  
  .game-link {
    font-family: "KoPubWorldDotum_Pro-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    color: #317ed6;
    text-decoration: none;
    padding: 10px 20px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
  }
  
  .game-link:hover {
    background-color: #48b8e9;
  }
  
  .main-link {
    font-family: "KoPubWorldDotum_Pro-Medium", Helvetica;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
    position: absolute;
    bottom: 20px;
  }