/*Home.css*/
@font-face {
  font-family: 'Jalnan2';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
}

@font-face {
  font-family: 'KoPubWorldDotum_Pro-Medium';
  src: url('../fonts/KoPubWorld_Dotum_ProMedium.woff') format('woff');
}

@font-face {
  font-family: 'KoPubWorldDotum_Pro-Bold';
  src: url('../fonts/KoPubWorld_Dotum_ProBold.woff') format('woff');
}

@font-face {
  font-family: 'KCC-Dodamdodam';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/KCC-DodamdodamR.woff2') format('woff2');
}

@font-face {
  font-family: 'NanumGothic-ExtraBold';
  src: url('../fonts/NanumGothic-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'NanumGothic-Regular';
  src: url('../fonts/NanumGothic-Regular.woff2') format('woff2');
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.home-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home-content {
  flex: 1;
}

/* home1 */
.home-header {
  position: relative;
  width: 100%;
  height: 588px;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.home-header-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-content {
  position: relative;
  z-index: 2;
  padding: 20px;
}

.nas-develop h1 {
  font-family: 'Jalnan2';
  font-size: 40px;
  color: #ffffff;
  line-height: 1.2;
}

.shadow-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.content-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}

.nas-intro .NAS {
  color: #ffffff;
  font-family: "KoPubWorldDotum_Pro-Bold", Helvetica;
  font-size: 15px;
  line-height: 20px;
}

.top-wallet-button {
  position: absolute;
}

/* home2 */
.main {
  position: relative;
  max-width: 390px;
  background-color: #ffffff;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box
}

.about, .together, .platform {
  text-align: center;
  margin-bottom: 20px;
}  

.about .text-wrapper {
  color: #317ed6;
  font-family: "KoPubWorldDotum_Pro-Bold", Helvetica;
  font-size: 17px;
}

.together .together-nas {
  color: #000000;
  font-family: "NanumGothic-ExtraBold", Helvetica;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 2px;
}

.platform .text-wrapper {
  color: #484646;
  font-family: "KoPubWorldDotum_Pro-Bold", Helvetica;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.features-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 60px;
}

.features-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.feature-group {
  position: relative;
  width: 45%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
} 

.feature-group img {
  width: 60px;
  height: 60px;
  margin-bottom: 5px;
  object-fit: contain;
}

.image {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; 
}

.navigation {
  position: relative; 
  z-index: 1; 
}

.referral-group, .aquarium-group {
  width: 45%;
  max-width: 200px;
}

.feature-group .staking-english,
.feature-group .aquarium-eng,
.feature-group .game-eng,
.feature-group .referral-eng,
.feature-group .swap-eng {
  color: #484646;
  font-family: "NanumGothic-Bold", Helvetica;
  font-size: 13px;
}

.feature-group .staking-korean,
.feature-group .aquarium-ko, 
.feature-group .game-ko,
.feature-group .referral-ko,
.feature-group .swap-ko {
  color: #000000;
  font-family: "NanumGothic-ExtraBold", Helvetica;
  font-size: 15px;
}

.feature-group .staking-yield,
.feature-group .commission,
.feature-group .join,
.feature-group .reward,
.feature-group .transfer {
  color: #000000;
  font-family: "NanumGothic-Bold", Helvetica;
  font-size: 12px;
  line-height: 0.4;
}  

.features-row:last-child .feature-group {
  width: 100%;
}

.feature-group .swap-ko {
  margin-bottom: 15px;
}

.feature-group .transfer {
  margin-bottom: 15px;
}

.navigation {
  color: #ffb533;
  font-family: "NanumGothic-ExtraBold", Helvetica;
  font-size: 12px;
  margin-bottom: 12px;
  text-decoration: none; 
  display: inline-block; 
  background: none; 
  position: relative;
  z-index: 2;
  cursor: pointer;
  padding: 5px 10px;
}

.navigation .text-wrapper {
  color: inherit; /* 추가: 부모 요소의 색상 상속 */
}

/* home3 */
.footer {
  position: relative;
  width: 100%;
  height: 631px;
  overflow: hidden;
}

.footer .footer-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.footer .footer-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer-content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  padding: 50px 20px;
  box-sizing: border-box;
}

.echo {
  margin-top: 50px;
  margin-bottom: 10px;
}

.echo .text-wrapper {
  color: #000000;
  font-family: "NanumGothic-Regular", Helvetica;
  font-size: 13px;
  line-height: 1.4;
}

.nas-token {
  width: 100px;
  margin-bottom: 5px;
}

.nas-token .text-wrapper {
  color: #ffffff;
  font-family: "Jalnan2", Helvetica;
  font-size: 30px;
  text-align: center;
}

.earth, .one-door {
  margin-bottom: 20px;
}

.earth .flexcontainer, .one-door .flexcontainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.one-door .flexcontainer {
  margin-bottom: 30px;
}

.earth .text, .earth .text-wrapper,
.one-door .text, .one-door .text-wrapper {
  color: #ffffff;
  font-family: "NanumGothic-Regular", Helvetica;
  font-size: 13px;
  text-align: center;
  line-height: 1.3;
  margin: 1px 0;
}

.one-door .span {
  font-family: "NanumGothic-ExtraBold", Helvetica;
  font-size: 16px;
}

.logo {
  width: 120px;
  height: 120px;
}

.fishing {
  margin-bottom: 5px;
  margin-top: 40px;
}

.fishing .text-wrapper {
  color: #ffffff;
  font-family: "KCC-Dodamdodam", Helvetica;
  font-size: 20px;
  text-align: center;
  line-height: 0.3;
}

.bottom-wallet-button {
  position: relative;
}

/* 모바일 최적화 (최대 390px) */
@media screen and (max-width: 390px) {
  .home-content {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .header, .main, .footer {
    width: 100%;
    max-width: 100%;
  }

  .footer {
    height: auto;
    min-height: 631px;
  }
}