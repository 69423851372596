@font-face {
  font-family: 'jalnan2';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KoPubWorldDotum_Pro-Medium';
  src: url('../fonts/KoPubWorld_Dotum_ProMedium.woff') format('woff');
}
@font-face {
  font-family: 'KoPubWorldDotum_Pro-Bold';
  src: url('../fonts/KoPubWorld_Dotum_ProBold.woff') format('woff');
}

body {
  margin: 0;
  padding: 0;
}

.aquarium {
  min-height: 100vh;
  width: 100%;
  max-width: 390px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  font-family: "KoPubWorldDotum_Pro-Medium", Helvetica;
}

/* Aqua1 */
.aqua1 {
  position: relative;
  width: 100%;
  height: 593px;
}

.background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.background-image .aquarium-header {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aqua1-content {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.liquidityPool .text-wrapper {
  color: #ffffff;
  font-family: "KoPubWorldDotum_Pro-Medium", Helvetica;
  font-size: 15px;
  margin-top: 30px;
}

.aquarium-title h1 {
  color: #ffffff;
  font-family: "Jalnan2", Helvetica;
  font-size: 30px;
}

.coin-bonus .na {
  color: #ffffff;
  font-family: "KoPubWorldDotum_Pro-Bold", Helvetica;
  font-size: 15px;
  text-align: center;
  margin: 0;
}

/* Aqua2 */
.aqua2 {
  background-color: #195bd6;
  align-items: center;
}

.liquidity-box {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 50px;
  width: 80%; 
}

.token-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.token-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.token-info img {
  width: 27px;
  height: 27px;
  margin-right: 10px;
}

.nas-token-image {
  width: 30px;  
  height: 30px;
  margin-right: 12px;
}

.token-details {
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

.token-name {
  font-family: "KoPubWorldDotum_Pro-Bold", Helvetica;
  font-weight: 550;
  margin-right: 10px;
}

.required {
  color: red;
  font-size: 13px;
  margin-right: 10px;
}

.balance {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.token-input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 13px;
}

.warning {
  color: red;
  font-size: 12px;
  margin-bottom: 15px;
}

.supply-button {
  width: 100%;
  padding: 10px;
  background-color: #195bd6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 700;
}

.preview-button {
  width: 100%;
  padding: 10px;
  background-color: #48b8e9;
  color: #195bd6;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 700;
}

/* Aqua3 */
.aqua3 {
  background-color: #ffffff;
  padding: 20px;
  padding-bottom: 50px;
}

.info-box {
  margin-bottom: 15px;
}

.info-box p {
  margin-top: 0;
}

.info-box-header, .chart-header {
  display: flex;
  align-items: center;  
}

.check-image {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.liquidity-history {
  background-color: #195bd6;
  color: #ffffff;
  border-radius: 10px;
  width: 90%;
  margin-top: 25px;
  padding: 5px;
}

.liquidity-history h4 {
  font-size: 15px;
  margin-left: 15px;
}

.liquidity-history h5 {
  font-size: 13px;
  margin-left: 20px;
}

.liquidity-history ul {
  padding: 0;
  font-size: 12px;
  margin-left: 20px;
}

.liquidity-history li {
  font-size: 12px;
  margin-left: 25px;
}

.withdrawal-date {
  display: flex;
  align-items: center;
}

.withdrawal-date h6 {
  font-size: 15px;
  margin-left: 15px;
}

.withdrawal-date p {
  font-size: 15px;
  margin-left: 15px;
}

.main-link {
  font-family: "KoPubWorldDotum_Pro-Medium", sans-serif;
  font-size: 14px;
  color: #195bd6 !important;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
}

.aqua2, .aqua3 {
  display: flex;
  flex-direction: column;
}

.liquidity-box, .liquidity-history {
  width: 85%;
  max-width: 330px; 
  margin-left: auto;
  margin-right: auto;
}

/* 모바일 최적화 (최대 390px) */
@media screen and (max-width: 390px) {
  .aquarium {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .aqua1, .aqua2, .aqua3 {
    width: 100%;
    max-width: 100%;
  }

  .liquidity-box {
    width: 80%;
    align-items: center;
  }

  .aqua3 {
    height: auto;
    min-height: 631px;
  }

  .token-input, .duration-selection {
    width: 100%;
  }

  .duration-selection button {
    font-size: 12px;
  }

  .liquidity-history {
    align-items: center;
    width: 90%;
  }
}
