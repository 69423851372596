/* Referral.css */
@font-face {
  font-family: 'Jalnan2';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'KoPubWorldDotum_Pro-Bold';
  src: url('../fonts/KoPubWorld_Dotum_ProBold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'KoPubWorldDotum_Pro-Medium';
  src: url('../fonts/KoPubWorld_Dotum_ProMedium.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'NanumGothic_Regular';
  src: url('../fonts/NanumGothic-Regular.woff2') format('woff2');
  font-display: swap;
}

.referral {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  font-family: "KoPubWorldDotum_Pro-Bold", sans-serif;
  text-align: center;
}

.back-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.referral-back-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-wrapper {
  max-width: 390px;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px; /* main-link를 위한 공간 확보 */
}

.referral-intro {
  font-family: "KoPubWorldDotum_Pro-Medium", sans-serif;
  font-size: 15px;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #195bd6
}

h2 {
  font-family: "Jalnan2", sans-serif;
  color: #195bd6;
  font-size: 30px;
  margin-bottom: 15px;
}

.friend {
  font-family: "KoPubWorldDotum_Pro-Medium", sans-serif;
  font-size: 15px;
  margin-bottom: 10px;
  color: #195bd6;
}

.highlight {
  color: #ffb533;
  font-family: "Jalnan2", sans-serif;
}

.strong-reward {
  font-family: "KoPubWorldDotum_Pro-Bold", sans-serif;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 50px;
  color: #195bd6;
}

.main-link {
  font-family: "KoPubWorldDotum_Pro-Medium", sans-serif;
  font-size: 13px;
  color: #ffffff;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  bottom: 20px;
  left: 0;
  z-index: 10;
}

/* 모바일 최적화를 위한 미디어 쿼리 */
@media screen and (max-width: 390px) {
  .referral {
    padding: 10px;
  }
  
  .content-wrapper {
    width: 95%;
  }

  h2 {
    font-size: 24px;
  }

  .friend, .strong-reward {
    font-size: 14px;
  }

  .main-link {
    font-size: 13px;
    bottom: 10px;
  }
}